// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: '',
  //API_URL:'http://148.72.214.78/tkaff/api/'
  //API_URL:'http://148.72.214.78:1200/api/',
  //BASE_API_URL:'http://148.72.214.78:1200/',
  // API_URL:'http://103.155.84.59:1101/api/',
  // BASE_API_URL:'http://103.155.84.59:1101/',
  // API_URL:'http://103.155.84.59/tkaffapi/api/',
  // BASE_API_URL:'http://103.155.84.59/tkaffapi/',
  //API_URL:'http://localhost/tkaffapi/api/',
  //BASE_API_URL:'http://localhost/tkaffapi/',
   //API_URL:'https://localhost:5001/api/',
   //BASE_API_URL:'https://localhost:5001/',
//  API_URL:'http://148.72.206.209:267/api/',
//  BASE_API_URL:'http://148.72.206.209:267/',

 //PROD
//  API_URL:'https://api.tkaff.com/api/',
//  BASE_API_URL:'https://api.tkaff.com/images/',
 
 API_URL:'https://uatapi.tkaff.com/api/',
 BASE_API_URL:'https://uatapi.tkaff.com/images/',


  // firebase:  {
  //   apiKey: "AIzaSyAbhV8E94adf23fuTO8Lvx9ykmqMjZuvqA",
  //   authDomain: "tkaff-345011.firebaseapp.com",
  //   projectId: "tkaff-345011",
  //   storageBucket: "tkaff-345011.appspot.com",
  //   messagingSenderId: "606757061093",
  //   appId: "1:606757061093:web:233d7a316e138ca339ef0c",
  //   measurementId: "G-EEP40H5WWP"
  // },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
